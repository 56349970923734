<div class="container app-background">
    <div>
        <div class="rectangle-card">
            <div *ngIf="!selectUserBranch" class="rectangle-card-content">
                <div style="font-size: 18px; font-weight: 500">Welcome,</div><br>
                <div style="font-size: 16px;">Login to your account!</div>
                <form [formGroup]="loginForm">
                    <mat-form-field style="width: 100%; padding-bottom: 15px;">
                        <input matInput type="text" placeholder="Username" formControlName="username">
                        <mat-error *ngIf="username!.invalid">{{getusernameErrorMsg()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field style="width:100%; padding-bottom: 15px;">
                        <input matInput [type]="passwordHide ? 'password' : 'text'" placeholder="Password" formControlName="password" (keyup.enter)="login()">
                        <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="password!.invalid">{{getPasswordErrorMsg()}}</mat-error>
                    </mat-form-field>
                </form>
                <button matButton class="btn-success" (click)="login()">LOGIN</button>
            </div>
            <div *ngIf="selectUserBranch" class="rectangle-card-content">
                <mat-form-field class="form-field-background">
                    <mat-label>Select Branch</mat-label>
                    <mat-select [(value)]="selected" [formControl]="branchSelect">
                    <mat-option *ngFor="let option of branch" [value]="option.branch_id">{{option.branch_name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="branchSelect.invalid">
                       Please select the Branch
                     </mat-error>
                 </mat-form-field>
                 <button matButton class="btn-success" (click)="proceedWithBranch()">PROCEED</button>
            </div>
        </div>
    </div>
    <app-spinner *ngIf="showSpinner"></app-spinner>
</div>