import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';

import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SpinnerModule } from './spinner/spinner.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './services/loginServices/auth-interceptor';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NumberCommaFormatDirective } from './directives/number-comma-format.directive';
import { SessionTimeoutService } from './services/loginServices/session-timeout.service';
import { CountdownPopupComponent } from './services/loginServices/CountdownPopupComponent';


@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    NumberCommaFormatDirective,
    CountdownPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    SpinnerModule,
    ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
			progressBar: true,
			tapToDismiss: false
		}),	
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    SessionTimeoutService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
