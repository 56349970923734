<div class="page-overlay-wrapper">
    <!-- <div class="bee-spinner"></div> -->
    <!-- <div class="container">
        <div class="triangle"></div>
        <div class="triangle"></div>
      </div>
      <div class="container">
        <div class="triangle shadow"></div>
        <div class="triangle shadow"></div>
      </div> -->
      <div>
        <div class="loader"></div>
        <div style="    color: #ffffff; font-size: 20px;">Loading...</div>
      </div>
</div>