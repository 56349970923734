import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SpinnerService } from '../../spinner/spinner.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthenticationService,
                private _spinnerService: SpinnerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authToken = this._authService.getToken();
        const authRequest = req.clone({
            headers: req.headers.set('Authorization', "Bearer " + authToken)
        });
        const started = Date.now();
        this._spinnerService.show()

        return next.handle(authRequest)
        .pipe(tap((event: any) => {
            const elapsed = Date.now() - started;
            if (event instanceof HttpResponse) {
                this._spinnerService.hide();
            };
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                    this._spinnerService.hide();
            };
        }));
    }
}