import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { shareReplay } from "rxjs/operators";
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: boolean = false;
  private token!: any;
  private userId!: any;
  private branch!: any;
  private tokenTimer!: any;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) { }

  getToken(){
    return this.token;
  }

  getUserId(){
    return this.userId;
  }

  getBranch(){
    return this.branch;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  login(user: any) {
    let email = user.email;
    let password = user.password;
    return this.http.post('/api/login', {email, password}).pipe(shareReplay())
  }

  postUser(data : any) {
    return this.http.post<{token: string, userid: number}>(environment.apiUrl + '/login', data);
  }

  setLoginData(data: any) {
      const token = data.token; 
      const userId = data.userid;
      const expiresInDuration = data.expiresIn;
      this.setAuthTimer(expiresInDuration);
      this.token = token;
      this.userId = userId;
      const now = new Date();
      const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
      this.saveAuthData(token, data.userid, expirationDate);
  }

  setUserBranchData(data: any) {
    this.branch = data;
    localStorage.setItem('branch', data);
    this.setAuthenticationStatus();
  }

  updateUserBranchData(data: any) {
    this.branch = data;
    localStorage.setItem('branch', data);
  }

  setAuthenticationStatus() {
    if(this.token && this.userId && this.branch) {
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
      this.router.navigate(['/dashboard']);
    } else {
      this.isAuthenticated = false;
    }
  } 

  autoAuthUser() {
    const authInfo = this.getAuthData();
    if(!authInfo) {
      return;
    }
    const now = new Date();
    const expiresIn = authInfo.expirationDate.getTime() - now.getTime();
    if(expiresIn > 0) {
      this.token = authInfo?.token;
      this.userId = authInfo?.userid;
      this.branch = authInfo?.branch;
      if(this.token && this.userId && this.branch) {
        this.isAuthenticated = true;
        this.setAuthTimer(expiresIn / 1000);
        this.authStatusListener.next(true);
      } else {
        this.isAuthenticated = false;
      }
    }
  }

  logout() {
    this.token = null;
    this.userId = null;
    this.branch = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.clearAuthData();
    clearTimeout(this.tokenTimer);
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number) {
    this. tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, userid: any, expirationDate: Date) {
    localStorage.setItem('token', token);
    localStorage.setItem('userid', userid);
    localStorage.setItem('expiration', expirationDate.toISOString());
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('branch');
    localStorage.removeItem('expiration');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const userid = localStorage.getItem('userid');
    const branch = localStorage.getItem('branch');
    const expirationDate = localStorage.getItem('expiration');
    if(!token || !userid || !branch || !expirationDate) {
      return;
    }
    return {
      token: token,
      userid: userid,
      branch: branch, 
      expirationDate: new Date(expirationDate)
    }
  }

  getUserMenu(data?: any) {
    return this.http.get(environment.apiUrl + '/user/menu', {params: data});
  }

}
 