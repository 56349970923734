import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './spinner.component';

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		HttpClientModule
	],
	declarations: [SpinnerComponent],
	exports: [SpinnerComponent],
	entryComponents: [SpinnerComponent]
})
export class SpinnerModule { }