import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private userBranchDataBehaviour = new BehaviorSubject<any>(null);
	userData = this.userBranchDataBehaviour.asObservable();

  constructor(private http: HttpClient) { }

  updateUserBranchData(branch: any) {
		this.userBranchDataBehaviour.next(branch);
	}

  getUserBranch() {
    return this.http.get(environment.apiUrl + '/user/branch');
  }

  getCSOByBranch(branchId: any) {
    let params = {
      branch_id : branchId
    }
    return this.http.get(environment.apiUrl + '/user/cso/' + branchId);
  }

  getConfirmQCData(search: string, branch_id: any) {
    let params = {
      search_string: search,
      branch_id: branch_id
    }
    return this.http.get(environment.apiUrl + '/confirm-qc-data', {params: params});
  }

  confirmQC(data: any) {
    return this.http.post(environment.apiUrl + '/confirm-qc', data);  
  }

  getBankingDetail(household_id: any) {
    let params = {
      household_id : household_id
    }
    return this.http.get(environment.apiUrl + '/banking-detail', {params: params});
  }

  saveBankDetail(data: any) {
    return this.http.post(environment.apiUrl + '/save-bank-detail', data);
  }

  signatureVerification(hid: any) {
    let data = {
      household_id: hid
    }
    this.http.post(environment.php_url+'/API/forms/bank_signature_form.php', data,
    {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      responseType: 'blob' 
    }).subscribe((response) => {
      let filename = 'Signature_Verification';
      this.downloadPdf(response, filename)
    });
  }
  
  downloadPdf(data: any, filename: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url= window.URL.createObjectURL(blob);
    
    // create <a> tag dinamically
    var fileLink = document.createElement('a');
    fileLink.href = url;

    // it forces the name of the downloaded file
    fileLink.download = filename;

    // triggers the click event
    fileLink.click();
    // window.open(url);
  }
}

