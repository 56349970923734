import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from './services/loginServices/authentication.service';
import { SessionTimeoutService } from './services/loginServices/session-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'menu-framework';

  constructor( private _authService: AuthenticationService, private sessionTimeoutService: SessionTimeoutService ) {}

  ngOnInit() {
    this._authService.autoAuthUser();
    console.log('AppComponent initialized, session monitoring started');
  }
}
