import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from './PopupService';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private idleTimeout = 15 * 60 * 1000; // 15 minute
  private warningTime = 1 * 60 * 1000; // 1 minute

  private timeoutId: any;
  private warningTimeoutId: any;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private popupService: PopupService
  ) {
    console.log('SessionTimeoutService initialized');
    this.startMonitoring();
  }

  private resetTimer() {
    if (this.router.url === '/') {
      return; // Skip timeout handling on the root route (login page)
    }

    console.log('User activity detected, resetting idle timer');

    clearTimeout(this.timeoutId);
    clearTimeout(this.warningTimeoutId);

    // Start a new idle timer
    this.timeoutId = setTimeout(() => {
      this.triggerWarning();
    }, this.idleTimeout);

    console.log(`Idle timer started for ${this.idleTimeout / 1000} seconds`);
  }

  private triggerWarning() {
    if (this.router.url === '/') {
      return; // Skip warning and logout on the root route (login page)
    }

    console.log('User has been idle, triggering warning phase');

    const countdown = this.warningTime / 1000; // Convert to seconds

    // Show the countdown popup
    this.ngZone.run(() => {
      this.popupService.showPopup(countdown, () => {
        console.log('User clicked continue, resetting session timer');
        this.resetTimer();
      });

      // Warning phase starts, log out after the warning time if user doesn't click "Continue"
      this.warningTimeoutId = setTimeout(() => {
        this.logout();
        this.popupService.closePopup();
      }, this.warningTime);

      console.log(`Warning timer started for ${this.warningTime / 1000} seconds`);
    });
  }

  private logout() {
    console.log('Session timeout expired, logging out');
    this.clearAuthData();

    this.ngZone.run(() => {
      this.router.navigate(['/']); // Redirect to login or timeout page
    });
  }

  private clearAuthData() {
    console.log('Clearing auth data from localStorage');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    localStorage.removeItem('branch');
    localStorage.removeItem('expiration');
  }

  // Start listening to user activity
  private startMonitoring() {
    this.ngZone.runOutsideAngular(() => {
      ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'].forEach((event) => {
        window.addEventListener(event, () => this.resetTimer());
      });

      this.resetTimer(); // Start the idle timer initially
    });
  }

  // Stop listening to user activity
  public stopMonitoring() {
    ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'].forEach((event) => {
      window.removeEventListener(event, () => this.resetTimer());
    });

    clearTimeout(this.timeoutId);
    clearTimeout(this.warningTimeoutId);
  }
}
