import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appNumberCommaFormat]'
})
export class NumberCommaFormatDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) { this.el = this.elementRef.nativeElement; }

  ngOnInit() {
    this.el.value = this.el.value.toLocaleString();
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value: any) {
    this.el.value = value; // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    this.el.value = value.toLocaleString('en-IN');
  }

}
