// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://110.227.201.27:3000/api",
  //apiUrl: "http://103.219.41.46:3000/api",
  //apiUrl: "http://www.navadhan.com:3000/api",
  apiUrl: "https://nv-node-backend-prod-htyabn7jja-el.a.run.app/api",
  // apiUrl: "http://192.168.0.190:3500/api",
  python_url: 'https://pythonservice-prod-htyabn7jja-el.a.run.app',
  php_url: 'https://php.acen.digital'
  //php_url: 'http://103.219.41.46'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact  
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
