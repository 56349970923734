// countdown-popup.component.ts
import { Component, Input, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown-popup',
  template: `
    <div *ngIf="visible" class="popup">
      <div class="popup-content">
        <h2>Session Expiration Warning</h2>
        <p>Your session will expire in {{ countdown }} seconds.</p>
        <button class="btn-success" (click)="onContinue()">Continue</button>
      </div>
    </div>
  `,
  styles: [
    `
      .popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .popup-content {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
      }
      .btn-success {
        width: 60%;
        border: unset;
        color: white;
        background-color: #03045e;
        height: 40px;
        text-transform: uppercase;
        border-radius: 2px;
        cursor: pointer;
      }
    `,
  ],
})
export class CountdownPopupComponent implements OnInit, OnDestroy {
  @Input() countdown: number = 60; // Countdown in seconds
  @Input() visible: boolean = false; // Control visibility
  @Output() continueSession = new EventEmitter<void>();

  private countdownInterval: any;

  ngOnInit() {
    if (this.visible) {
      this.startCountdown();
    }
  }

  ngOnDestroy() {
    this.stopCountdown();
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.stopCountdown();
      }
    }, 1000); // Update every second
  }

  stopCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
  }

  onContinue() {
    this.stopCountdown();
    this.continueSession.emit();
  }
}
