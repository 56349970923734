import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from '../services/loginServices/authentication.service';
import { DashboardService } from '../services/dashboardServices/dashboard.service';
import { SpinnerService } from '../spinner/spinner.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  selected = 'branch_1';
  loginForm! : FormGroup;
  showSpinner!: boolean;
  showSpinnerSubs!: Subscription;
  users : Array<any> = [];
  userIsAuthenticated: boolean = false;
  passwordHide: boolean = true;
  selectUserBranch: boolean = false;
  branchSelect: any;
  branch: Array<any> = [];
  private authListenerSubs!: Subscription;

  constructor(private fb: FormBuilder, 
              private _authService : AuthenticationService,
              private _dashboardService: DashboardService, 
              private router: Router, 
              private toastr: ToastrService,
              private _spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.authListenerSubs = this._authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });

    this.showSpinnerSubs = this._spinnerService.getVisibility().subscribe(res => {
      this.showSpinner = res;
    });

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.branchSelect = new FormControl('', [Validators.required]);

  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.showSpinnerSubs.unsubscribe();
  };

  login() {
    if(this.loginForm.invalid) {
      return;
    } else {
      let data = {
        id : null,
        username: this.username?.value, 
        password : this.password?.value
      }
      this._authService.postUser(data).subscribe((response: any) => {
        if(response.token) {
          this._authService.setLoginData(response);
          this._dashboardService.getUserBranch().subscribe((response: any) => {
            this.branch = response.branch;
            this.selectUserBranch = true;
          }, (err) => {
            this.toastr.error(err.error.message, 'Error!!');
          })
        } else {
          this.toastr.error('login unsucessful!');
        }
      }, (err: any) => {
        this.toastr.error(err.error.message, 'Error!!');
      });
    }
  };

  proceedWithBranch() {
    if(this.branchSelect.invalid || this.branchSelect.value == "") {
      this.branchSelect.markAsTouched();
    } else {
      this._authService.setUserBranchData(this.branchSelect.value);
    }
  }

  getusernameErrorMsg() {
		return this.username!.hasError('required') ? 'Please enter your username.' : '';
	}

  getPasswordErrorMsg() {
		return this.password!.hasError('required') ? 'Please enter your password.' : '';
	}

  get username() {
		return this.loginForm.get('username');
	}

  get password() {
    return this.loginForm.get('password');
  }

}
