// popup.service.ts
import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { CountdownPopupComponent } from './CountdownPopupComponent';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private popupRef: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  showPopup(countdown: number, onContinue: () => void) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(CountdownPopupComponent);
    this.popupRef = factory.create(this.injector);
    this.popupRef.instance.countdown = countdown;
    this.popupRef.instance.visible = true;
    this.popupRef.instance.continueSession.subscribe(() => {
      onContinue();
      this.closePopup();
    });
    this.appRef.attachView(this.popupRef.hostView);
    document.body.appendChild(this.popupRef.location.nativeElement);
  }

  closePopup() {
    if (this.popupRef) {
      this.appRef.detachView(this.popupRef.hostView);
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }
}
